import {
  createEffect,
  createMemo,
  Show,
  createSignal,
  For,
  Switch,
  Match,
  on,
  Suspense,
} from "solid-js";
import {
  createAsync,
  useParams,
  useSearchParams,
  A,
  type RouteDefinition,
  type RouteSectionProps,
} from "@solidjs/router";
import { getProductData } from "~/dataFunctions";
import { useProduct, useProductPricing } from "~/services/products";
import { useSessionContext, useSiteContext } from "~/utils/contexts";
import { ThresholdStatus, stockThreshold } from "~/utils/threshold";
import { ProductPriceResponse } from "~/services/roma-api/products/types";
import { Product } from "~/components/product";
import { PHOTOFRAME, GALLERYFRAME, MOULDING, MIRROR } from "~/utils/products";
import Body from "~/components/Body";
import { BaseProductSlider } from "~/components/ui";
import { Modal } from "~/components/utility";
import Breadcrumb from "~/components/Breadcrumb";
import { launchLiveChat } from "~/utils/livechat";
import { Icon } from "solid-heroicons";
import {
  chatBubbleLeft,
  calculator,
  magnifyingGlassMinus,
  magnifyingGlassPlus,
  arrowPathRoundedSquare,
} from "solid-heroicons/outline";
import { Accordion } from "~/components/ui";
import { Img } from "~/components/image";
import tnc from "~/assets/tnc.png";
import otp from "~/assets/otp.png";
import { ProductImage } from "~/components/product";
import { Menu } from "~/components/utility";
import { capitalize } from "~/utils/helpers";
import { Carousel } from "~/components/ui";
import { PDPImageGallery } from "~/components/product/product-page";
import { isServer } from "solid-js/web";

export const route = {
  preload: ({ params }) => useProduct(params.sku, { available_list: true, suggestions: true }),
} satisfies RouteDefinition;

export type FormattedPricing = ProductPriceResponse & {
  availableAs?: string[];
  defaultPlantLowStock?: boolean;
  anyPlantLowStock?: boolean;
};

export default function ProductLanding(props: RouteSectionProps) {
  const { isPartner } = useSessionContext();
  const { global, breakpoints } = useSiteContext();
  const [calcModal, setCalcModal] = createSignal(false);
  const params = useParams();
  const product = createAsync(async () =>
    useProduct(params.sku, { available_list: true, suggestions: true })
  );
  // TODO > isPartner/session check here?

  createEffect(() => {
    console.log("PRODUCT >>> ", product());
  });

  type FormattedPricing = ProductPriceResponse & {
    availableAs?: string[];
    defaultPlantLowStock?: boolean;
    anyPlantLowStock?: boolean;
  };

  const type = createMemo(() => {
    if (props.location.pathname.includes("photo-frame")) {
      return PHOTOFRAME;
    } else if (props.location.pathname.includes("gallery-frame")) {
      return GALLERYFRAME;
    } else if (props.location.pathname.includes("mirror")) {
      return MIRROR;
    } else {
      return MOULDING;
    }
  });

  // const suggestionList = createMemo(() => {
  //   if (product() && product()?.Suggestions) {
  //     const arr = product()?.Suggestions.reduce((memo, item) => {
  //       memo.push({
  //         type: type(),
  //         data: item,
  //       });
  //       return memo;
  //     }, []);
  //     return arr;
  //   }
  // });

  // createEffect(() => console.log("PRICING FROM [SKU]", pricing()));

  return (
    <Suspense>
      <Show when={product() /*&& options() */}>
        {/* <Title>{product().SEOTitle} RomaMoulding.com</Title> */}
        {/* ! TESTING NO INDEXING */}
        {/* <Meta name="Algolia crawler" content="noindex" />
        <Meta name="description" content={product().SEODescription} />
        <Meta name="keywords" content={product().SEOKeywords} />
        <Meta
          name="og:image"
          content={`${import.meta.env.VITE_ROMA_CDN}/mouldings/${
            product().SKU
          }-1.png`}
        /> */}
        <Body>
          <Modal
            open={calcModal}
            onClose={() => {
              setCalcModal(false);
            }}
          >
            <div class="border border-orange-500 text-orange-500">
              FrameCalculator here..
            </div>
          </Modal>
          <Breadcrumb
            class="mt-4"
            breadcrumbs={[
              // { pageTitle: "Home", url: "/" },
              { pageTitle: "Shop", url: "/shop" },
              {
                pageTitle: product()?.Category,
                url: `/shop?category=${product()?.Category}`,
              },
              {
                pageTitle: `${product()?.SKU} ${product()?.Collection} - ${
                  product()?.ColourDescription
                }`,
                url: "/",
              },
            ]}
          />
          <main class="grid md:grid-cols-product-page items-start sm:mt-7 boundary-outer relative gap-2 sm:gap-10">
            <div class="md:col-span-4  ">
              <PDPImageGallery type={type()} product={product} />
            </div>
            <div class="flex flex-col md:col-span-2 divide-y child:py-4 sticky top-4 -mt-4">
              <Suspense>{props.children}</Suspense>
              <Accordion
                name="sustainability"
                label="Sustainability"
                labelClass="font-bold"
                icon="Chevron"
                contentHeight="Large"
                childClass="text-roma-dark-grey text-sm child:pb-3 leading-7"
              >
                <p>
                  This moulding was made in Italy in accordance with the highest
                  EU material and production standards and will be shipped to
                  you using nearly 100% recycled materials.
                </p>
                <p>
                  We are committed to sourcing natural, environmentally
                  sustainable woods, we use only water-based paints, and our
                  wood paste is 100% natural, composed of fine wood particles,
                  natural oils, and glues.
                </p>
                <p>
                  Roma also partners with One Tree Planted and the Nature
                  Conservancy to positively impact communities and ecosystems
                  all over the world.{" "}
                </p>
                <div class="flex gap-6 items-center">
                  <a
                    href="https://onetreeplanted.org/"
                    target="_blank"
                    class="w-24 block"
                  >
                    <Img
                      src={otp}
                      alt="One Tree Planted Logo"
                      width={96}
                      height={22}
                    />
                  </a>
                  <a
                    href="https://www.nature.org/"
                    target="_blank"
                    class="w-24 block"
                  >
                    <Img
                      src={tnc}
                      alt="The Nature Conservatory Logo"
                      width={96}
                      height={28}
                    />
                  </a>
                </div>
                <A
                  aria-label="Learn more about our sustainability initiatives"
                  class="text-sm text-roma-blue"
                  href="/about/sustainability"
                >
                  <span class="sr-only">
                    Learn more about our sustainbility initiatives
                  </span>
                  Learn More
                </A>
              </Accordion>
              <Accordion
                name="return-exchange"
                label="Returns & Exchanges"
                labelClass="font-bold"
                icon="Chevron"
                childClass="text-roma-dark-grey text-sm leading-7 child:pb-3"
              >
                <p>
                  In our mission to Move, Inspire and WOW, we at Roma Moulding
                  would like to ensure that you are truly happy with your
                  purchase.
                </p>
                <p>
                  If you receive your order and you are not completely satisfied
                  with the product, returning it is just as easy as ordering it!
                  <A
                    aria-label="Learn more about our return and exchange policy"
                    href="/support/returns-exchanges"
                    class="text-roma-blue ml-1"
                  >
                    <span class="sr-only">
                      Learn more about our return and exchange policy
                    </span>
                    Learn More
                  </A>
                </p>
              </Accordion>
              <div class="flex gap-4 text-xs font-medium text-roma-dark-grey items-center justify-between ">
                {/* <Show when={import.meta.env.VITE_PROD === true}> */}
                {/* <Show when={true}>
                  <button
                    aria-label="Need help? Launch support chat"
                    class="flex items-center gap-2 hover:text-roma-blue"
                    onClick={() => {
                      launchLiveChat(
                        isPartner() && session.name ? session.name : "",
                        isPartner() && session.company_number
                          ? session.company_number
                          : ""
                      );
                    }}
                  >
                    <Icon path={chatBubbleLeft} class="w-5" />
                    <p>Need Help?</p>
                  </button>
                </Show> */}
                <Show
                  // when={false}
                  when={
                    !props.location.pathname.includes("gallery-frame") &&
                    !props.location.pathname.includes("photo-frame")
                  }
                >
                  <button
                    aria-label="Open frame calculator"
                    onClick={() => {
                      setCalcModal(true);
                    }}
                    class="flex items-center gap-2 hover:text-roma-blue"
                  >
                    <Icon path={calculator} class="w-5" />
                    <p>Frame Calculator</p>
                  </button>
                </Show>
                <Show when={isPartner()}>
                  <Show when={product()} keyed>
                    {(prod) => (
                      <div class="border border-orange-500 text-orange-500">
                        AssetDownloadButton here
                      </div>
                    )}
                  </Show>
                </Show>
              </div>
            </div>
          </main>
          <section class="mt-10 sm:mt-28 boundary-outer flex flex-col gap-4 sm:gap-8 pb-10 sm:pb-20 border border-red-500">
            {/* <BaseProductSlider
              title="You May Also Like"
              productList={suggestionList()}
              analyticsListName="PDP Recommendation"
            /> */}
            {/* <RecentlyViewed /> */}
          </section>
        </Body>
      </Show>
    </Suspense>
  );
}
